import { assign, has } from "lodash-es";
import queryString from "query-string";
import type { Simplify } from "type-fest";

import { logger } from "~/debug/Logging";
import { type PathMatch, useMatch } from "~/utils/mflt-react-router";
import type { StrictPartial, StringKeys } from "~/utils/typing";

type PathSegment<K> = {
  name: K;
  parameter?: boolean;
  optional?: boolean;
};

type QueryParameter<K> = {
  name: K;
  optional?: boolean;
};

/**
 * Anything that is valid as a path segment.
 *
 * Also accepting `number` for entity IDs.
 */
export type PathSegmentParameterValue = string | number;

/**
 * Anything that stringify can render easily.
 *
 * It will call `toString()` on anything else, but that might hide a bug.
 */
export type QueryParameterValue =
  | string
  | number
  | boolean
  | null
  | undefined
  | string[]
  | number[]
  | boolean[];

/**
 * An URL template for simple route building and binding.
 *
 * The `AA` type param contains the name to type mapping,
 * most commonly `{ param: QueryParameterValue, ... }`.
 */
export class URLTemplate<AA extends {}> {
  readonly base: string | undefined;

  readonly pathSegments: readonly PathSegment<StringKeys<AA>>[] = [];

  readonly queryParameters: readonly QueryParameter<StringKeys<AA>>[] = [];

  readonly hashParameters: readonly QueryParameter<StringKeys<AA>>[] = [];

  readonly binds: Readonly<AA>;

  /**
   * @param baseArg The base for the URL or `undefined` in case of a relative template.
   */
  constructor(
    baseArg?:
      | string
      | undefined
      | Pick<
          URLTemplate<any>,
          | "base"
          | "pathSegments"
          | "queryParameters"
          | "hashParameters"
          | "binds"
        >,
  ) {
    if (typeof baseArg === "string" || baseArg === undefined) {
      this.base = baseArg;
      this.binds = {} as AA;
    } else {
      const { base, pathSegments, binds, queryParameters, hashParameters } =
        baseArg;
      this.base = base;
      this.pathSegments = pathSegments as readonly PathSegment<
        StringKeys<AA>
      >[];
      this.binds = binds;
      this.queryParameters = queryParameters as readonly QueryParameter<
        StringKeys<AA>
      >[];
      this.hashParameters = hashParameters as readonly QueryParameter<
        StringKeys<AA>
      >[];
    }
  }

  private clone(
    assignments: Partial<URLTemplate<any>>,
    Ctor: typeof URLTemplate = this.constructor as any,
  ) {
    return new Ctor(assign({ ...this }, assignments)) as any;
  }

  addPathSegment(pathSegment: string): URLTemplate<AA> {
    this.checkLastPathSegmentNotOptional();
    return this.clone({
      pathSegments: [...this.pathSegments, { name: pathSegment }],
    });
  }

  addPathSegmentParameter<K extends string, V = PathSegmentParameterValue>(
    key: K,
    optional = false,
  ): K extends keyof AA ? never : URLTemplate<AA & { [P in K]: V }> {
    this.checkLastPathSegmentNotOptional();
    return this.clone(
      {
        pathSegments: [
          ...this.pathSegments,
          {
            name: key,
            parameter: true,
            optional,
          },
        ],
      },
      URLTemplate,
    );
  }

  addOptionalPathSegmentParameter<
    K extends string,
    V = PathSegmentParameterValue,
  >(key: K): K extends keyof AA ? never : URLTemplate<AA & { [P in K]?: V }> {
    return this.addPathSegmentParameter(key, true);
  }

  addQueryParameter<
    K extends string,
    V extends QueryParameterValue = QueryParameterValue,
  >(key: K): K extends keyof AA ? never : URLTemplate<AA & { [P in K]: V }> {
    return this.clone({
      queryParameters: [...this.queryParameters, { name: key }],
    });
  }

  addOptionalQueryParameter<
    K extends string,
    V extends QueryParameterValue = QueryParameterValue,
  >(key: K): K extends keyof AA ? never : URLTemplate<AA & { [P in K]?: V }> {
    return this.clone({
      queryParameters: [...this.queryParameters, { name: key, optional: true }],
    });
  }

  addOptionalHashParameter<
    K extends string,
    V extends QueryParameterValue = QueryParameterValue,
  >(key: K): K extends keyof AA ? never : URLTemplate<AA & { [P in K]?: V }> {
    return this.clone({
      hashParameters: [...this.hashParameters, { name: key, optional: true }],
    });
  }

  bindAll<
    SA extends AA & { [K in keyof SA]: K extends keyof AA ? AA[K] : never },
  >(binds: SA): URLTemplate<{}> {
    const template = this.bind(binds);
    if (!template.areAllParametersBound(false)) {
      logger.reportError(
        new Error(
          `Missing binds for ${template
            .unboundKeys(false)
            .map((k) => `'${k}'`)
            .join(", ")} for template ${this.toString()}`,
        ),
      );
    }

    // Type assertion because sadly `Omit<AA, Extract<keyof SA, string>>`
    // isn't the same as `{}`.
    return template as URLTemplate<{}>;
  }

  bind<PA extends StrictPartial<PA, AA>>(
    binds: PA,
  ): URLTemplate<Omit<AA, StringKeys<PA>>> {
    const existingBindKeys = new Set(Object.keys(this.binds));
    const newBinds = { ...binds };

    Object.entries(newBinds).forEach(([k, v]) => {
      if (v === undefined || v === (this.binds as any)[k]) {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete (newBinds as any)[k];
      } else if (existingBindKeys.has(k)) {
        logger.reportError(
          new Error(
            `Rebinding not allowed. '${k}' already bound to '${
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              this.binds[k as keyof AA]
            }'`,
          ),
        );
      }
    });

    if (Object.keys(newBinds).length === 0) {
      return this as any;
    }

    return this.clone({
      binds: { ...this.binds, ...newBinds },
    });
  }

  stripPrefix(template: URLTemplate<any>): URLTemplate<{}> {
    return this.clone({
      pathSegments: this.pathSegments.slice(template.pathSegments.length),
    });
  }

  toURL(): URL {
    return new URL(this.toString(), window.location.href);
  }

  toString(): string {
    const queryStringValue = this.queryString();
    const fragmentString = this.fragmentString();
    const segments = this.substitutedPathSegments();
    const path = (
      this.base === undefined ? segments : [this.base, ...segments]
    ).join("/");
    let result = path;
    if (queryStringValue !== "") {
      result += `?${queryStringValue}`;
    }
    if (fragmentString !== "") {
      result += `#${fragmentString}`;
    }
    return result;
  }

  get parameterKeys(): string[] {
    return this.getParameterKeys(true);
  }

  get bound() {
    return this.areAllParametersBound(true);
  }

  unboundKeys(includeOptional: boolean): string[] {
    const existingBindKeys = new Set(Object.keys(this.binds));
    return this.getParameterKeys(includeOptional).filter(
      (k) => !existingBindKeys.has(k),
    );
  }

  areAllParametersBound(includeOptional: boolean): boolean {
    return this.unboundKeys(includeOptional).length === 0;
  }

  getParameterKeys(includeOptional: boolean): string[] {
    return [
      ...this.pathSegments.filter(({ parameter }) => parameter),
      ...this.queryParameters,
      ...this.hashParameters,
    ]
      .filter(({ optional }) => (includeOptional ? true : !optional))
      .map(({ name }) => name);
  }

  protected checkLastPathSegmentNotOptional() {
    const lastSegment = this.pathSegments.at(-1);
    if (lastSegment?.optional) {
      logger.reportError(
        new Error(
          `Template has optional path segment '${lastSegment.name}', cannot add another after`,
        ),
      );
    }
  }

  protected substitutedPathSegments() {
    return this.pathSegments.map(({ name, parameter, optional }) => {
      if (!parameter) {
        return name;
      }
      if (!has(this.binds, name)) {
        // path-to-regexp compatible parameter format
        return `:${name}${optional ? "?" : ""}`;
      }
      const value = this.binds[name as keyof AA];
      return encodeURIComponent(value as any);
    });
  }

  protected queryString(): string {
    const defaults = this.queryParameters
      .filter((p) => !p.optional)
      .map((p) => [p.name, p.optional ? undefined : ""]);
    const nonQueryParameterNames = new Set<string>(
      [...this.pathSegments, ...this.hashParameters].map((s) => s.name),
    );
    const present = Object.entries(this.binds).filter(
      (e) => !nonQueryParameterNames.has(e[0]) && e[1] !== undefined,
    );
    const result = Object.fromEntries([...defaults, ...present]);

    return queryString.stringify(result);
  }

  protected fragmentString(): string {
    const hashParameterNames = new Set<string>(
      this.hashParameters.map((p) => p.name),
    );
    const present = Object.entries(this.binds).filter((e) =>
      hashParameterNames.has(e[0]),
    );
    const result = Object.fromEntries(present);
    return queryString.stringify(result);
  }
}

export type URLTemplateAttributes<T extends URLTemplate<any>> =
  T extends URLTemplate<infer AA> ? Simplify<AA> : never;

export function useTemplateRouteMatch<AA extends {}>(
  template: URLTemplate<AA>,
  { exact = false }: { exact?: boolean } = {},
): PathMatch<keyof AA & string> | null {
  return useMatch({ path: template.toString(), end: exact });
}
