/* eslint-disable no-restricted-imports */
/**
 * This file wraps the "react-router" API, such that the match.param values
 * are URI-decoded before they are passed on.
 */
import { withSentryReactRouterV6Routing } from "@sentry/react";
import { useContext, useMemo } from "react";
import {
  Routes as OriginalRoutes,
  // eslint-disable-next-line camelcase
  UNSAFE_RouteContext,
} from "react-router";

import { URLTemplate } from "~/utils/URLTemplate";

export * from "react-router";
export { BrowserRouter, Link } from "react-router-dom";

/**
 * The `useMatches` function of react router only works with the data router,
 * which we don't use (yet). This is a workaround.
 *
 * https://reactrouter.com/en/6.11.2/hooks/use-matches
 */
function useUnsafeMatches() {
  const { matches } = useContext(UNSAFE_RouteContext);
  return matches;
}

export function useCurrentRouteTemplate() {
  const match = useUnsafeMatches().at(-1);

  return useMemo(() => {
    const element = match?.route?.element;
    if (element == null) return undefined;
    if (typeof element !== "object" || !("props" in element)) return undefined;
    const template = element.props?.template;
    if (template instanceof URLTemplate) return template;
    return undefined;
  }, [match]);
}

export const Routes = withSentryReactRouterV6Routing(OriginalRoutes);
