import {
  type BrowserOptions,
  captureException,
  init as initSentry,
  reactRouterV6BrowserTracingIntegration,
  replayIntegration,
} from "@sentry/react";
import { isAxiosError, isCancel } from "axios";
import { type ErrorInfo, useEffect } from "react";

import { init as initSpotlight } from "./spotlight";

import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "~/utils/mflt-react-router";

export { setUser } from "@sentry/react";

export { captureException };

// Community-provided list
// https://docs.sentry.io/platforms/javascript/
const ignoreErrors = [
  // Random plugins/extensions
  "top.GLOBALS",
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  "originalCreateNotification",
  "canvas.contentDocument",
  "MyApp_RemoveAllHighlights",
  "http://tt.epicplay.com",
  "Can't find variable: ZiteReader",
  "jigsaw is not defined",
  "ComboSearch is not defined",
  "http://loading.retry.widdit.com/",
  "atomicFindClose",
  // Facebook borked
  "fb_xd_fragment",
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
  // reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268
  "bmi_SafeAddOnload",
  "EBCallBackMessageReceived",
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  "conduitPage",
  // Ignore an error that appears to come from CefSharp (embedded Chromium).
  // See https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/20
  "Object Not Found Matching Id",
  // Network errors, ad blockers
  "TypeError: Failed to fetch",
  "TypeError: NetworkError when attempting to fetch resource.",
  "Request aborted",
  "Error: Unable to preload CSS",
  "Network Error", // AxiosError with no further info
  "AbortError",
  "timeout exceeded",
  // Ignore when Chunks can't be loaded (b/c of a stable browser window after a deploy)
  // We still should proactively notify the user about that (once we have WebSockets).
  "ChunkLoadError",
];

// Community-provided list
// https://docs.sentry.io/platforms/javascript/
const denyUrls = [
  // Facebook flakiness
  /graph\.facebook\.com/i,
  // Facebook blocked
  /connect\.facebook\.net\/en_US\/all\.js/i,
  // Woopra flakiness
  /eatdifferent\.com\.woopra-ns\.com/i,
  /static\.woopra\.com\/js\/woopra\.js/i,
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  // Other plugins
  /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
  /webappstoolbarba\.texthelp\.com\//i,
  /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
];

const REACT_COMPONENT_DID_CATCH_MECHANISM = "react.componentDidCatch";
const REACT_COMPONENT_STACK_EXTRA_KEY = "react.componentStack";

export function sentryInit() {
  initSentry({
    release: APP_CONFIG.GIT_COMMIT_SHA || undefined,
    environment: APP_CONFIG.ENVIRONMENT || undefined,
    dsn: APP_CONFIG.SENTRY_FRONTEND_DSN || undefined,
    // Capture 10% of all sessions
    replaysSessionSampleRate: 0.1,
    // Of the remaining 90% of sessions, if an error happens start capturing
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors,
    denyUrls,
    normalizeDepth: 10,
    integrations: [
      reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      replayIntegration({
        maskAllText: true,
        blockAllMedia: false,
      }),
    ],
    beforeSend,
  });
  void initSpotlight();
}

const beforeSend: BrowserOptions["beforeSend"] = (
  event,
  { originalException: exc },
) => {
  // The component tree crashed hard, so report it.
  if (event.tags?.mechanism === REACT_COMPONENT_DID_CATCH_MECHANISM) {
    return event;
  }

  // non network errors are always reported
  if (!isAxiosError(exc)) {
    return event;
  }

  const status = exc.response?.status;

  // generic network error (e.g. DNS, unrouteable, etc. without a status code)
  if (!status) {
    return null;
  }

  // reported by the backend
  if (status >= 500) {
    return null;
  }

  // not actionable
  if ([401, 403, 404].includes(status)) {
    return null;
  }

  // we remain with other 4xx class errors, which could indicate frontend code
  // not handling all edge cases or making an invalid request, so let's let them through

  return event;
};

/**
 * Create an error from a message with a stack trace.
 * @param message The error message
 */
function makeError(message: string): Error {
  try {
    throw new Error(message);
  } catch (error: unknown) {
    return error as Error;
  }
}

/**
 * Convert an unknown value to an Error (Sentry doesn't like non-Error objects).
 * @param value The value to convert
 */
function ensureError(value: unknown): Error {
  if (value instanceof Error) {
    return value;
  }

  if (isCancel(value)) {
    return makeError(`Cancel: ${value.message}`);
  }

  return makeError(String(value));
}

/**
 * Sends a fatal React error and send it to Sentry.
 * @param error The error to send
 * @param errorInfo Additional information about the error
 */
export function componentDidCatch(
  error: unknown,
  errorInfo?: ErrorInfo,
): string | undefined {
  try {
    return captureException(ensureError(error), (scope) => {
      return scope
        .setTags({
          mechanism: REACT_COMPONENT_DID_CATCH_MECHANISM,
        })
        .setExtras({
          [REACT_COMPONENT_STACK_EXTRA_KEY]: errorInfo?.componentStack,
        });
    });
  } catch (sentryError) {
    // Can't use logger here.
    console.error("Failed to send error to sentry:", sentryError);
    return undefined;
  }
}
