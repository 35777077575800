import { enableMapSet } from "immer";
import { Suspense, lazy } from "react";
import { createRoot } from "react-dom/client";

import { BootRipple } from "~/components/BootRipple";
import { sentryInit } from "~/utils/sentry";

sentryInit();

const App = lazy(() => import("./App").then((m) => ({ default: m.App })));

enableMapSet();

const rootElement = document.getElementById("root");

if (!rootElement) throw new Error("Failed to find root element '#root'");

createRoot(rootElement).render(
  <Suspense fallback={<BootRipple />}>
    <App />
  </Suspense>,
);

if (import.meta.hot) {
  import.meta.hot.on("vite:beforeUpdate", () => {
    void import("antd/es/message").then((m) => {
      // delete persistent messages
      m.default.destroy();
    });
  });
}
